/* Root Variables */
:root {
  --bs-primary: #1969ab;
  --bs-primary-dark: #1a5789;
  --bs-primary-rgb: 25,105,171;
  --bs-body-font-family: 'Source Sans Pro', var(--bs-font-sans-serif);
}

/*
  Fonts
  ----
*/
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('../fonts/source-sans-pro-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 200;
  src: local(''),
       url('../fonts/source-sans-pro-200italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-200italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('../fonts/source-sans-pro-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('../fonts/source-sans-pro-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('../fonts/source-sans-pro-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('../fonts/source-sans-pro-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('../fonts/source-sans-pro-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  src: local(''),
       url('../fonts/source-sans-pro-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('../fonts/source-sans-pro-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('../fonts/source-sans-pro-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('../fonts/source-sans-pro-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 900;
  src: local(''),
       url('../fonts/source-sans-pro-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/source-sans-pro-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}



/*
  Base Styles
  ----
*/



/*
  Typography
  ----
*/

h1 {
  color: var(--bs-primary);
  font-weight: 700;
}



/*
  Components
  ----
*/

.btn-primary {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-primary:hover {
  background-color: var(--bs-primary-dark);
  border-color: var(--bs-primary-dark);
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary:hover {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-orange {
  background-color: #E57449;
  border-color: #E57449;
  color: white;
}

.btn-orange:hover {
  background-color: #c7623c;
  border-color: #c7623c;
  color: white;
}


/*
  Header
  ----
*/

header.site-header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  height: auto;
  padding: 32px 0;
}

header.site-header .logo {
  height: 70px;
}

header.site-header .header-nav {
  margin-top: 24px;
  position: relative;
}

header.site-header .header-nav a {
  color: #FFF;
  text-decoration: none;
}

header.site-header .header-nav a:not(:last-child) {
  padding-right: 10px;
  border-right: 1px solid #FFF;
}

header.site-header .header-nav a:not(:first-child) {
  padding-left: 6px;
}

header.site-header .header-nav a:hover {
  text-decoration: underline;
}

@media (min-width: 992px) {
  header.site-header {
    flex-direction: row;
    height: 124px;
  }

  header.site-header .header-nav {
    position: absolute;
    right: 24px;
    margin: 0;
  }
}



/*
  Content
  ----
*/

/* Hero */
@media (min-width: 992px) {
  .hero p {
    max-width: 50vw;
  }
}


/* Calculator */
.calculator-container {
  background-size: cover;
}

.no-webp .calculator-container {
  background-image: url('/img/calc-background.jpg');
}

.webp .calculator-container {
  background-image: url('/img/calc-background.webp');
}

.calculator {
  width: 100%;
  max-width: 710px;
}

.slider {
  width: 100%;
}

.slider__labels {
  margin-top: 8px;
}

/*progress support*/
input[type=range].slider-progress {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--value) - var(--min)) / var(--range));
  --sx: calc(0.5 * 2em + var(--ratio) * (100% - 2em));
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  background: transparent;
}

input[type=range]::-ms-track {
  width: 100%;
  cursor: pointer;

  /* Hides the slider so custom styles can be added */
  background: transparent; 
  border-color: transparent;
  color: transparent;
}

input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: white;
  border: 4px solid var(--bs-primary);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px;
}

input[type=range]::-moz-range-thumb {
  -webkit-appearance: none;
  background: white;
  border: 4px solid var(--bs-primary);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  cursor: pointer;
}

input[type=range]::-webkit-slider-runnable-track {
  background: #b5b5b5;
  height: 8px;
  cursor: pointer;
}

input[type=range]::-moz-range-track {
  background: #b5b5b5;
  height: 8px;
  cursor: pointer;
}

input[type=range]::-ms-track {
  background: #b5b5b5;
  height: 8px;
  cursor: pointer;
}

input[type=range].slider-progress::-webkit-slider-runnable-track {
  background: linear-gradient(var(--bs-primary),var(--bs-primary)) 0/var(--sx) 100% no-repeat, #b5b5b5;
}

input[type=range].slider-progress::-moz-range-track {
  background: linear-gradient(var(--bs-primary),var(--bs-primary)) 0/var(--sx) 100% no-repeat, #b5b5b5;
}

.static-bar {
  border: 1px solid var(--bs-primary);
  position: relative;
  width: 100%;
  height: 26px;
  transition: width 1s ease-in-out;
}

/*
  The initial percentage values of inserate and vermarktung.
  These values will change based on the slider and the variables modified in `main.js`
*/
.static-bar--inserate {
  --percentage: 1.9%;
}

.static-bar--vermarktung {
  --percentage: 20%;
}

.static-bar::before, .static-bar::after {
  position: absolute;
  white-space: nowrap;
  text-indent: 8px;
  overflow: hidden;
  content: attr(data-content);
  top: 0;
  left: 0;
  transition: width 0.2s ease-in-out;
}

.static-bar::before {
  background: #FFF;
  color: var(--bs-primary);
  width: 100%;
}

.static-bar::after {
  background: var(--bs-primary);
  color: #FFF;
}

.static-bar.static-bar--inserate::after {
  width: var(--percentage);
}

.static-bar.static-bar--vermarktung::after {
  width: var(--percentage);
}

/* Slider Responsive Styles */
@media (min-width: 992px) {
  .slider {
    max-width: 80%;
  }
}



/* Features */
.features ul li {
  margin-bottom: 16px;
}

.features img {
  height: 32px;
  margin-right: 16px;
}

.features .btn {
  padding-left: 32px;
  padding-right: 32px;
}



/* Price List */

table.preise {
  border-color: #EEE;
}

table.preise td {
  padding: 4px 10px;
}

table.preise td h5 {
  font-weight: bold;
}

table.preise td.preis {
  font-weight: bold;
  width: 95px;
}

table.preise td img {
  margin-bottom: 10px;
}

.modal .rent, .modal .sell {
  display: none;
}

.modal.rent .rent {
  display: table-cell;
}

.modal.sell .sell {
  display: table-cell;
}

/* Vermarktung Modal */

.vermarktung-modal ul img {
  width: 24px;
  margin-right: 16px;
}

.vermarktung-modal ul li {
  margin-bottom: 12px;
}




/* Calendly */

.calendar {
  background-repeat: no-repeat;
  background-size: cover;
  height: 680px;
  margin-top: 100px;
  position: relative;
}

.no-webp .calendar {
  background-image: url('/img/map-medium.png');
}

.webp .calendar {
  background-image: url('/img/map-medium.webp');
}

.calendar::after {
  content: '';
  position: absolute;
  z-index: 1;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.25);
}

.calendly-inline-widget {
  position: relative;
  z-index: 2;
  top: -175px;
  opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .calendly-inline-widget {
    transition: opacity 1s ease, top 1s ease;
  }

  .calendar::after {
    transition: opacity 1s;
  }
}

.calendar-transition::after {
  opacity: 1;
}

.calendly-inline-widget-transition {
    opacity: 1;
    top: -125px;
}




/*
  Footer
  ----
*/

footer {
  color: #fff;
}

footer a {
  color: #fff;
  text-decoration: none;
}

footer a:hover {
  color: #fff;
  text-decoration: underline;
}
